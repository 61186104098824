module.exports={
    author:"vLite",
    title:'vLite',
    icon:require("@/assets/icons/logo/vLite/favicon.png"),
    assetpath:`vlite`,
    config_path:["https://vlite-config.firebaseio.com/preprod/web/vlite/v1.json?print=pretty"],
    _fbId: "550400871992321",
    _googleId: "458080364267-r3r16jn2flj2pkgd45820ftpri45c2kv.apps.googleusercontent.com",
    pwaData:{
        title:"vLite",
        maskColor:"#30d158",
        msColor:"#da532c",
        themeColor:"#ffffff"
      },
    playerConfig:{
        color: "#FF9F0A",
        logo:require("@/assets/icons/logo/vLite/watermark.svg"),
        fingerprint:true
    },
    pushConfiguration:[
        ['messagingId','458080364267'],
        ['vendorName','vLite']
    ],
    vendorAssets:'vLite',
    bodyID:"vlite"
    }